import * as React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import './previewImage.scss';
import { useDialog } from '@rocketmakers/armstrong';

interface IProps {
  image: FluidObject;
  borderColor?: string;
}

export const PreviewImage: React.FC<IProps> = ({ image, borderColor = '#FFF' }) => {
  const { open, portal } = useDialog(
    () => (
      <Img
        className="preview-dialog-image"
        fluid={image}
        style={{ paddingLeft: `calc(85vh * ${image.aspectRatio})`, height: '100%', borderColor: borderColor }}
        fadeIn={true}
        backgroundColor="#F6F6F6"
      />
    ),
    {
      hostElement: 'body',
      className: 'preview-image-dialog',
    }
  );

  return (
    <>
      <div className="preview-image" onClick={open}>
        <Img fluid={image} fadeIn={true} backgroundColor="#F6F6F6" />
      </div>
      {portal}
    </>
  );
};
